import React from "react";

const Human = ({ color }) => {
  return (
    <svg viewBox="0 0 26 31" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6089 14.5107C16.4508 14.5107 19.5654 11.3962 19.5654 7.55418C19.5654 3.7122 16.4508 0.597656 12.6089 0.597656C8.76688 0.597656 5.65234 3.7122 5.65234 7.55418C5.65234 11.3962 8.76688 14.5107 12.6089 14.5107Z"
        fill="#333333"
      />
      <path
        d="M12.6087 17.9883C5.6451 17.9883 0 23.6334 0 30.597H25.2174C25.2174 23.6334 19.5723 17.9883 12.6087 17.9883Z"
        fill="#333333"
      />
    </svg>
  );
};
export default Human;
